const OrigDate = Date;

/* Time-Travel */

// Reset global Date
export function unmockDate() {
  if (!Date["mocked"]) return;
  Date = OrigDate;
}

export function mockDate(offset: number) {
  // Reset mock if offset changed
  if (!!Date["mocked"]) {
    if (offset === Date["offset"]) return;
    unmockDate();
  }

  // Replace global Date w/ Proxy
  Date = new Proxy(OrigDate, {
    // offset new Date()
    construct: function (Target, args: Parameters<DateConstructor>): Date {
      // props to https://stackoverflow.com/a/43160428/671457
      if (args.length === 0) return new Target(OrigDate.now() - offset);
      return new Target(...args);
    },

    get: function (Target, prop, receiver) {
      // used to detect mocked Date
      if (prop === "mocked") return true;
      if (prop === "offset") return offset;

      // offset Date.now()
      if (prop === "now") {
        return () => new Target(OrigDate.now() - offset).getTime();
      }

      return Reflect.get(Target, prop, receiver);
    },
  });
}
